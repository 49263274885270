import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import './media.scss';
import App from './App';
import { ContextProvider } from './Context';
import { BrowserRouter } from 'react-router-dom';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<ContextProvider>
		<BrowserRouter>
			<App />
		</BrowserRouter>
	</ContextProvider>
)