import NavAdmin from "./NavAdmin";
import NavBottom from "./NavBottom";
import NavTop from "./NavTop";

export default function Navs() {
	return (
		<>
			<NavTop />
			<NavAdmin />
			<NavBottom />
		</>
	)
}